import React from "react";
import axios from "axios";

export default class BlogPostPage extends React.Component {
  //What are going to ve our vars
  state = {
    user: "",
    pswd: "",
  };

  //Handle changes on inputs
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  //Handle submit button
  handleSubmit = (event) => {
    event.preventDefault();
    alert(` User :  ${this.state.user} Password :  ${this.state.pswd}`);
    console.log(this.state);

    axios({
      method: "post",
      url: `https://testserver.heliotvilchis.com/login.php`,
      headers: { "content-type": "application/json" },
      data: this.state,
    }).then(
      (response) => {
        console.log("Response :" + response);
        console.log("Response data :" + response.data);
        console.log("Response status :" + response.status);
        console.log("Response statustext :" + response.statusText);
        console.log("Response headers :" + response.headers);
        console.log("Response config :" + response.config);
      },
      (error) => {
        console.log("Error :" + error);
      }
    );
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          User
          <input
            type="text"
            name="user"
            value={this.state.user}
            onChange={this.handleInputChange}
          />
        </label>
        <label>
          Password
          <input
            type="text"
            name="pswd"
            value={this.state.pswd}
            onChange={this.handleInputChange}
          />
        </label>
        <button type="submit">Login</button>
      </form>
    );
  }
}
